.root {
  text-align: left;
}

.pageTitle {
  margin-bottom: 20px;
}

.phase {
  margin: 10px 0 20px;
}

.phaseContent {
  margin-top: 2em;
}

.collapsible {
  margin: 5px 0;
}
