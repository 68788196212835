.root {
  text-align: left;
}

.section {
  margin: 20px 0;
}

.submitSection {
  text-align: right;
  margin-bottom: 50px;
}

.rightActions {
  width: 100%;
  justify-content: flex-end;
}

.panelRoot {
  background-color: #fff;
}

.panel {
  padding-top: 5px;
  border-radius: 4px !important;
  margin-top: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.leftColumnContent {
  padding-right: 5em;
}

.avatars {
  & > .ant-avatar:not(:first-child) {
    margin-left: -20px;
  }
}

.deliverableButton {
  display: block;
  text-align: left;
  color: #1890ff !important;
  padding: 0;

  & > svg {
    color: #8e8e93 !important;
  }

  &[disabled] {
    color: #000 !important;

    & > svg {
      opacity: 0;
    }
  }
}
