.root {
  width: 100%;
  padding-bottom: 70px;
}

.left {
  text-align: left;
}

.table {
  margin-top: 20px;
  margin-bottom: 50px;
}

.tableActions {
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  justify-content: end;
  align-items: flex-end;
}

.pageTitle {
  text-align: left;
  margin: 10px 0 20px;
}
